@import '../../../index.scss';

.language-button, .language-button-n {
    border: none;
    text-transform: uppercase;
    color: $black;
    font-weight: bold;
    background-color: transparent;
    cursor: pointer;
    margin: 5px;
}

.language-button-n {
    color: $white; 
}