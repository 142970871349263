.subpage-header, .subpage-header-n {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    h1 {
          color: #000;
          position: absolute;
          margin-top: 120px;
          font-size: 40px;
          text-transform: uppercase;
          z-index: 0;
          border-radius: 2rem;
          padding: 0.4em;
          text-align: center;
        }
    .background-green {
      width: 100%;
        height: 300px;
        background-color:#000;
        font-size: 18px;
        background-image: url("../../../assets/green-bcg.jpg");
        background-repeat: no-repeat;
        background-size:contain auto;
        background-position: right;
       
    }
    
    .background-blue {
      width: 100%;
      height: 300px;
      background-color:#FFF;
      font-size: 18px;
      background-image: url("../../../assets/blue-bcg.jpg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right;

    }
}

.subpage-header-n {
  background-color: #000;
  h1 {
    color: #fff;
  }
}



@media only screen and (min-width: 1024px){ 
  .subpage-header, .subpage-header-n {
    h1 {
          font-size: 60px;
        }

}
}