.service-list {
    margin: 30px 0px;
    list-style-type: none;
    font-size: 20px;
    font-weight: bold;
} 
p.service-p {
    padding-bottom: 50px;
}

@media only screen and (min-width: 1024px){ 

.services-options-wrapper {
    margin-top: 50px;
    display: flex;
flex-direction: row;
justify-content: space-between;
}
}