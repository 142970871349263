.menu-button-mobile {
    display: initial;
    margin-right: 20px;
 
  .btn {
    width: 30px;
    cursor: pointer;
  }
  
  span {
    display: block;
    width: 100%;
    border-radius: 3px;
    height: 2px;
    transition: all .3s;
    position: relative;
  }
  
  span + span {
    margin-top: 8px;
  }
  
  .active span:nth-child(1) {
    animation: ease .7s top forwards;
  }
  
  .not-active span:nth-child(1) {
    animation: ease .7s top-2 forwards;
  }
  
  .active span:nth-child(2) {
    animation: ease .7s scaled forwards;
  }
  
  .not-active span:nth-child(2) {
    animation: ease .7s scaled-2 forwards;
  }
  
  .active span:nth-child(3) {
    animation: ease .7s bottom forwards;
  }
  
  .not-active span:nth-child(3) {
    animation: ease .7s bottom-2 forwards;
  }
  
  @keyframes top {
    0% {
      top: 0;
      transform: rotate(0);
    }
    50% {
      top: 22px;
      transform: rotate(0);
    }
    100% {
      top: -1px;
      transform: rotate(45deg);
    }
  }
  
  @keyframes top-2 {
    0% {
      top: 22px;
      transform: rotate(45deg);
    }
    50% {
      top: 22px;
      transform: rotate(0deg);
    }
    100% {
      top: 0;
      transform: rotate(0deg);
    }
  }
  
  @keyframes bottom {
    0% {
      bottom: 0;
      transform: rotate(0);
    }
    50% {
      bottom: 22px;
      transform: rotate(0);
    }
    100% {
      bottom: 22px;
      transform: rotate(135deg);
    }
  }
  
  @keyframes bottom-2 {
    0% {
      bottom: 22px;
      transform: rotate(135deg);
    }
    50% {
      bottom: 22px;
      transform: rotate(0);
    }
    100% {
      bottom: 0;
      transform: rotate(0);
    }
  }
  
  @keyframes scaled {
    50% {
      transform: scale(0);
    }
    100% {
      transform: scale(0);
    }
  }
  
  @keyframes scaled-2 {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  
}


@media only screen and (min-width: 1024px){ 
    .menu-button-mobile {
        display: none;
    }
}