.thermovision, .nightvision {

    padding-bottom: 100px;
}
.closure {
    padding: 50px 50px 50px;
    font-weight: bold;
    font-size: 20px;
}
@media only screen and (min-width: 1024px){ 
    .closure {
        padding: 50px 100px 50px;
        font-weight: bold;
        font-size: 24px;
    }
}



