.footer-container {
    width: 100%;
    height: 300px;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    font-size: 14px;

    .moon-phase-container {
      display: flex;
      align-items: center;
      margin-top: 20px;
      img {
        width: 50px;
      }
    }
    
    a {
    text-decoration: none;
    cursor: pointer;
      &:nth-child(2) {
          margin-left: 30px;
      }
    }
    .ico-container {
    position: relative;
    padding: 10px;
    border: none;
    background-color: transparent;
    outline: none;
    font-weight: bold;
    text-transform: uppercase;
    display: inline-block;
    margin: 30px 20px 0;
    img {
        height: 20px;
    }
  }
}

@media only screen and (min-width: 1024px){ 
  .footer-container {
    height: auto;
    padding: 0px 100px 25px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
  }
}