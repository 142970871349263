.cta-container {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 30px;
    font-weight: bold;
    //margin-top: 50px;
.cta-text {
  padding-top: 50px;
}
    
    .cta-buttons-wrapper {
      button {
      background-color: transparent;
      font-size: 20px;
      margin-top: 100px;
      margin-bottom: 30px;
      position: relative;
      border: none;
      background-color: transparent;
      color: #000;
      cursor: pointer;
      padding: 10px;
      outline: none;
      font-weight: bold;
      text-transform: uppercase;
      display: inline-block;
      margin: 50px;
      z-index: 1;
      
        &:hover::after,
        &:hover::before {
         width: 100%;
          height: 100%;
        }
        }
        a {
          width: 100%;
          height: 100%;
          z-index: 16;
          padding: 10px;
          text-decoration: none;
          position: relative;
          background-color: transparent;
        }
    }
  }



  @media only screen and (min-width: 1024px){ 
    .cta-container {
      height: 380px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 30px;
        font-weight: bold;
        
        .cta-buttons-wrapper {
          margin-top: 20px;
          button {
          background-color: transparent;
          font-size: 28px;
          margin-top: 100px;
          margin-bottom: 30px;
          position: relative;
          border: none;
          background-color: transparent;
          cursor: pointer;
          padding: 10px;
          outline: none;
          font-weight: bold;
          text-transform: uppercase;
          display: inline-block;
          margin: 50px;
          z-index: 1;
           
            &:hover::after,
            &:hover::before {
             width: 100%;
              height: 100%;
            }
            }
            a {
              width: 100%;
              height: 100%;
              z-index: 16;
              padding: 10px;
              text-decoration: none;
              position: relative;
            }
        }
      }
      
            a {
    
              color: #fff;
    
            }
        }
      
  

  .cta-border-green, .cta-border-blue {
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, #000, #23b213, #000) 1;
    border-left: 0px;
    border-right: 0px;
  }

  .cta-border-blue {
    border-image: linear-gradient(to right, #fff, #08C3D5, #fff) 1;
  }