@import '../../../index.scss';

#hide-checkbox {
  opacity: 0;
  height: 0;
  width: 0;
}

.toggle {
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 60px;
  height: 30px;
  background: $black;
  border-radius: 50px;
  transition: 500ms;
  overflow: hidden;
  margin-left: 100px;
  margin-right: 50px;
  border: 1px solid $green;
}

.toggle-button {
  position: absolute;
  display: inline-block;
  top: 1px;
  left: 2px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #FAEAF1;
  overflow: hidden;
//   box-shadow: 0 0 35px 4px rgba(255, 255, 255);
  transition: all 500ms ease-out;
}

.crater {
  position: absolute;
  display: inline-block;
  background: #FAEAF1;
  border-radius: 50%;
  transition: 500ms;
}

.crater-1 {
  background: #FFFFF9;
  width: 26px;
  height: 26px;
  left: 4px;
  bottom: 4px;
}

.crater-2 {
  width: 10px;
  height: 10px;
  top: -2px;
  left: 14px;
}

.crater-3 {
  width: 6px;
  height: 6px;
  top: 10px;
  right: -2px;
}

.crater-4 {
  width: 3px;
  height: 3px;
  top: 14px;
  left: 10px;
}

.crater-5 {
  width: 5px;
  height: 5px;
  top: 4px;
  left: 6px;
}

.crater-6 {
  width: 5px;
  height:5px;
  top: 20px;
  left: 14px;
}

.star {
  position: absolute;
  display: inline-block;
  border-radius: 50%;
  background: transparent;
  box-shadow: 1px 0 2px 1px rgba(255, 255, 255, 1);
  background: #Fff;

}

.star-1 {
    width: 0.5px;
    height: 0.5px;
  right: 10px;
  bottom: 10px;
}

.star-2 {
    width: 0.5px;
    height: 0.5px;
  right: 25px;
  top: 15px;
}

.star-3 {
    width: 0.5px;
    height: 0.5px;
  right: 20px;
  bottom: 20px;
}

.star-5 {
    width: 0.5px;
    height: 0.5px;
  right: 10px;
  bottom: 20px;
}
#cloud {
  display: none;
  }
#hide-checkbox:checked + .toggle {
  background: $blue;
}

#hide-checkbox:checked + .toggle .toggle-button {
  background: #F7FFFF;
  transform: translateX(30px);
  box-shadow: 0 0 35px 5px rgba(255, 255, 255,1);
}

#hide-checkbox:checked + .toggle .toggle-button .crater {
  transform: rotate(-45deg) translateX(70px);
}

#hide-checkbox:checked + .toggle #cloud {
  background: rgba(255, 255, 255, 1);
  width: 12px; 
  height: 5px;
  border-radius: 150px;	
  position: absolute;
  top:13px;
  left: 10px;
  display: initial;
  
}

#cloud:before {
  content: '';
  background: rgba(255, 255, 255, 1);
  position: absolute;
  width: 8px;
  height: 7px;
  border-radius: 50%;
  top: -3px;
  left: 2px;
}



@keyframes move {
  0% {
    transform: none;
  }
  
  25% {
    transform: translateX(0px);
  }
  
  100% {
    transform: translateX(0px);
  }
}



@media only screen and (max-width: 1023px){ 

.toggle {
  margin-left: 20px;
  margin-right: 20px;
}
}