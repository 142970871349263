.text-img-section-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
.text-section-wrapper {
    width: 100%;
       
    .text-section-title {
        font-size: 24px;
        margin-bottom: 20px;
    }
}

.img-section-wrapper {
    width: 50%;
    text-align: center;
    margin-bottom: 100px;
    img {
        width: 100%;
        padding: 0px 0px 50px;
    }
}
}


@media only screen and (min-width: 1024px){ 
    .text-img-section-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    .text-section-wrapper {
        width: 47%;
           
        .text-section-title {
            font-size: 24px;
            margin-bottom: 20px;
        }
    }
    
    .img-section-wrapper {
        width: 50%;
        text-align: center;
        margin-bottom: 100px;
        img {
            width: 100%;
            padding: 0px 0px 50px;
        }
    }
    }
}