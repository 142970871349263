.quote-container {
    margin: 0 auto;
    text-align: center;
    max-width: 500px;
    position: relative;
    img {
        height: 50px;
        position: absolute;
        right: -20px;
        top: -80px;
    }
p {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin: 110px auto 50px;
}
}

@media only screen and (min-width: 1024px){ 
    .quote-container {
        img {
            height: 100px;
            right: -100px;
        }
    p {
        font-size: 28px;
    }
    }
}