.menu-mobile-container {
    position: fixed;
    top: 120px;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index:2;
    .blue {
        
    }

    .menu-mobile-wrapper {
        width: 100%;
        .menu-mobile-link, .menu-mobile-link-blue {
            width: 100%;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            border-bottom: 1px solid;
            border-image: linear-gradient(to right, #000, #23b213, #000) 1;
            
            a {
                text-decoration: none;
            }
        }
        .menu-mobile-link-blue {
            border-image: linear-gradient(to right, #fff, #08C3D5, #fff) 1;
            }

    }
}