@import '../../../index.scss';

//day

.progress-bar, .progress-bar-n {
    position: fixed;
    width: 100%;
    height: 6px;
    top: 120px;
    left: 0;
    z-index: 4;
    background-color: $white;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  
    &::-webkit-progress-bar {
      background-color: transparent;
    }
  
    &::-webkit-progress-value {
      background-color: $blue;
    }
  
    &::-moz-progress-bar {
      background-color: $blue;
    }
  }

//night

  .progress-bar-n {
    background-color: $black;
  
    &::-webkit-progress-value {
      background-color: $green;
    }
  
    &::-moz-progress-bar {
      background-color: $green
    }
  }