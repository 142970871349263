    .advantages-container {
        padding: 0 50px; 
        .advantages-wrapper {
            display: flex;
           flex-direction: column;
            margin: 0 auto;
           padding: 50px 0;
        }

    }

@media only screen and (min-width: 1024px){ 
    .advantages-container {
        padding: 0 100px; 
        .advantages-wrapper {
        justify-content: space-between;
       flex-direction: row;
       padding: 50px 0px 0;
    }
    }
   
}
