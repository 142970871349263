.rules-content, .rules-content-n {
    padding: 50px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
   
    .paragraph {
    margin: 50px 0 30px 0;
    position: relative;
    padding: 20px;
    border: none;
    background-color: transparent;
    outline: none;
    font-weight: bold;
    display: inline-block;
    text-align: center;
    font-size: 20px;
    margin: 50px auto 30px;
    span {
        display: block;
    }
      &::after,
     &::before {
          content: '';
        display: block;
        position: absolute;
        width: 20%;
        height: 20%;
        border: 2px solid;
        transition: all 0.6s ease;
        border-radius: 2px;
      }
        &::after {
           bottom: 0;
        right: 0;
        border-top-color: transparent;
        border-left-color: transparent;
        border-bottom-color: #08C3D5;
        border-right-color: #08C3D5;
      }
      &::before {
          top: 0;
        left: 0;
        border-bottom-color: transparent;
        border-right-color: transparent;
        border-top-color: #08C3D5;
        border-left-color: #08C3D5;
      }
  }
p {
    margin: 20px 0 0;
    span {
        font-weight: bold;
    }
}
}


.rules-content-n {
background-color: #000;
color: #fff;
 
  .paragraph {
  span {
    color: #23b213;
  }

      &::after {
      border-bottom-color: #23b213;
      border-right-color: #23b213;
    }
    &::before {
      border-top-color: #23b213;
      border-left-color: #23b213;
    }
}
}


@media only screen and (min-width: 1024px){ 
  .rules-content, .rules-content-n {
    padding: 100px;
  }
}