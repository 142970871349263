@import '../../../index.scss';

.header-container, .header-container-n {
    width: 100%;
    height: 120px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    transition: all 0.6s;
    background-color: $white;
    z-index: 5;
    .header-buttons-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
  
}

.header-container-n {
    background-color: $black;
}


@media only screen and (min-width: 1024px) {
    .header-container, .header-container-n {
        width: 100%;
        height: 120px;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 100px;
        transition: all 0.6s;
        background-color: $white;
        z-index: 5;
        .header-buttons-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
      
    }
    
    .header-container-n {
        background-color: $black;
    }
}