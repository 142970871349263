
.contact-container {
    display: flex;
    flex-direction: column;     

    h1 {
        position: absolute;
        text-align: center;
    }
      
    img.contact-header-img {
        position: absolute;
        top: 0;
        left: 0;
        height: 50vh;
        width: 100%;
    }
    h1 {
margin-top: 100px;
font-size: 60px;
text-transform: uppercase;
    }
    .contact-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 50px;
        overflow: hidden;

        img {
            width: 150%;
            padding-top: 50px;
        }
        .adress-wrapper {
            font-weight: bold;
            font-size: 20px;
            position: relative;
            padding: 60px;
            border: none;
            background-color: transparent;
            outline: none;
            font-weight: bold;
            display: inline-block;
            text-align: center;
            font-size: 20px;
            margin: 0 auto;
            span {
                display: block;
            }
                    span {
                display: block;
                line-height: 2.5;
            }
        }
    }
}



@media only screen and (min-width: 1024px) {
    
.contact-container {
    .contact-content {
        flex-direction: row;
        padding: 100px;

        img {
            width: 50%;
            padding-top: 0px;
        }
        .adress-wrapper {
            margin: 0 ;
        }
    }
}
}
