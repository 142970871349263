.about-us-wrapper {
   padding: 0 50px;

}

@media only screen and (min-width: 1024px){ 
   .about-us-wrapper {
      padding: 0 100px;
      .about-us {
         padding-bottom: 100px;
      }
   }
}