
    h3.category-title{
    font-size: 28px;
    padding-top: 50px;
    padding-bottom: 30px;
    padding-left: 100px;
    text-transform: uppercase;
    }

    .products-content {
        padding: 0px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 auto;
    }

@media only screen and (min-width: 1024px) {
    
    h3.category-title{
        font-size: 28px;
     padding: 30px 100px;
        }
    
        .products-content {
            padding: 100px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: 0 auto;
            padding-bottom: 0;
        }
}