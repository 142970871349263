.faq-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;

    .faq-content {
        padding: 0 50px;
    }
}

@media only screen and (min-width: 1024px) {
    .faq-container {   
        .faq-content {
            padding: 0 100px;
        }
    }
}

