$green: #23b213;
$blue: #08C3D5;
$black: #000;
$white: #fff;

@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;500;600;700&display=swap');

* {
  transition: all 0.6s;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Heebo', sans-serif;
}

html {
  scrollbar-width: none;
  -webkit-scrollbar: none !important;
}

body::-webkit-scrollbar {
  display: none;
}


.green {
  color: $white;
  background-color: $black;
  a {
    color: $white;
  }
  span.point {
    color: $green;
  }
  &::selection {
    background-color: $green;
  }
}

.green-border, .blue-border {
  &::after,
  &::before {
       content: '';
     display: block;
     position: absolute;
     width: 20%;
     height: 20%;
     border: 2px solid;
     transition: all 0.6s ease;
     border-radius: 2px;
   }
     &::after {
        bottom: 0;
     right: 0;
     border-top-color: transparent;
     border-left-color: transparent;
     border-bottom-color: $green;
     border-right-color: $green;
   }
   &::before {
       top: 0;
     left: 0;
     border-bottom-color: transparent;
     border-right-color: transparent;
     border-top-color: $green;
     border-left-color: $green;
   }
}

.blue {
  color: $black;
  background-color: $white;
  a {
    color: $black;
  }
  span.point {
    color: $blue;
  }
  &::selection {
    background-color: $blue;
  }
}

.blue-border {
  color: $black;
  &::after {
  border-bottom-color: $blue;
  border-right-color: $blue;
}
&::before {
  border-top-color: $blue;
  border-left-color: $blue;
}
}

p {
  font-size: 20px;
  text-align: justify;
}



.subpage-container {
  width: 100%;
  min-height: 100vh;
  margin-top: 120px;
  padding: 0 100px;
}


.bold {
  font-weight: bold;
}

.section-container {
  width: 100%;
  padding: 100px;
}

// .blue {
//   color: #08C3D5;
// }
// .green {
//   color: #23b213;
// }

.subpage-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}


