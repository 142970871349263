.product {
  width: 300px;  
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  margin: 0 auto;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 100px;

  a {
    color: #000;
    text-decoration: none;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 3;
  }

    &:hover::after,
    &:hover::before {
      width: 100%;
      height: 100%;
    }
            .product-img{
                width: 200px;
            }
            .product-title {
                font-size: 20px;
                font-weight: bold;
                text-align: center;
                span {
                    display: block;
                    margin: 10px 0;
                }
            }
        }


 @media only screen and (min-width: 1024px) {

  .product {
    margin: 0 30px;
    margin-bottom: 100px;
  
          }
 }
